import * as React from "react";
import { Typography, Grid } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";

// components
import Page from "../utils/page";
import AreeCard from "../components/aree-card";
import Hero from "../utils/hero";
import theme from "../utils/theme";
import Section from "../utils/section";
import Link from "../utils/link";

// markup
const Servizi = () => {
    return (
        <Page
            title="I nostri servizi"
            description="Offriamo una vasta gamma di servizi per la salute della persona. Controlli cardiaci, Dieta, Alimentazione, Allergie e molto altro."
        >
            <Hero
                color="#fff"
                title="I nostri Servizi"
                description="Offriamo una vasta gamma di servizi per la salute della persona."
                background={theme.palette.primary.main}
                backgroundImage={
                    <StaticImage
                        style={{ height: "100%" }}
                        quality={100}
                        src="../images/farmacia-settimo-miglio-cosmesi.jpg"
                        alt="Farmacia Settimo Miglio servizi"
                        layout="fullWidth"
                    />
                }
            />

            <Section>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" gutterBottom component="h2" color="primary">
                            Controlli cardiaci
                        </Typography>

                        <ul>
                            <li>
                                <Link
                                    type="internal"
                                    href="/servizi/holter-cardiaco"
                                    underline="always"
                                    title="Scopri di più sull'holter cardiaco"
                                >
                                    Holter cardiaco 24-48h
                                </Link>
                            </li>
                            <li>Ecocardiogramma</li>
                            <li>Holter Pressorio 24-48h</li>
                            <li>Misurazione pressione</li>
                        </ul>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" gutterBottom component="h2" color="primary">
                            Dieta e alimentazione
                        </Typography>

                        <ul>
                            <li>Test per intolleranze alimentari</li>
                            <li>Test di prima istanza per celiachia</li>
                            <li>Test intolleranza al lattosio</li>
                            <li>Misurazione impedenziometrica</li>
                            <li>Percorsi di educazione alimentare per il controllo del peso</li>
                            <li>Autoanalisi del glucosio, emoglobina glicata, colesterolo (HDL, LDL), trigliceridi.</li>
                        </ul>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" gutterBottom component="h2" color="primary">
                            Benessere intestinale
                        </Typography>

                        <ul>
                            <li>Test genetico per la salute dell'intestino e dell'intero organismo (biomplan)</li>
                        </ul>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" gutterBottom component="h2" color="primary">
                            Allergie
                        </Typography>

                        <ul>
                            <li>Test allergeni inalanti e alimentari</li>
                        </ul>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" gutterBottom component="h2" color="primary">
                            Salute della pelle
                        </Typography>

                        <ul>
                            <li>Test della pelle</li>
                        </ul>
                    </Grid>
                </Grid>
            </Section>

            <Section>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={6}>
                        <AreeCard />
                    </Grid>
                </Grid>
            </Section>
        </Page>
    );
};

export default Servizi;
